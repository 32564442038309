import React from 'react'

const BankIcon: React.FC = () => {
  return (
    <svg width='36' height='24' viewBox='0 0 60 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='60' height='40' rx='4' fill='currentColor' />
      <path d='M15.0625 33.9688H43.9375' stroke='white' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M43.9375 13.3437H15.0625L28.3849 5.42095C28.7175 5.20717 29.1046 5.09351 29.5 5.09351C29.8954 5.09351 30.2825 5.20717 30.6151 5.42095L43.9375 13.3437Z'
        stroke='white'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M41.875 17.4688V29.8438H37.75V17.4688'
        stroke='white'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.5625 17.4688V29.8438H27.4375V17.4688'
        stroke='white'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.25 17.4688V29.8438H17.125V17.4688'
        stroke='white'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { BankIcon }
