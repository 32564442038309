import { useSearchParams as baseUseSearchParams } from 'react-router-dom'

import { isArray } from '@guiker/lodash'

export const useSearchParams = () => {
  const [baseSearchParams, setSearchParams] = baseUseSearchParams()

  const getSearchParam = (key: string): string => {
    const params = baseSearchParams.getAll(key)

    if (isArray(params) && params?.length) return params[0]
    else if (typeof params === 'string') return params
    else return undefined
  }

  const getSearchArrayParams = (key: string) => {
    const param = getSearchParam(key)
    if (!!param && typeof param === 'string') return [param]
    return param
  }

  const addSearchParam = (key: string, value: string | string[]) => {
    if (Array.isArray(value)) {
      baseSearchParams.delete(key)
      value.map((v) => baseSearchParams.append(key, v))
      setSearchParams(baseSearchParams)
    } else {
      baseSearchParams.set(key, value)
      setSearchParams(baseSearchParams)
    }
  }

  return { getSearchParam, getSearchArrayParams, setSearchParams, addSearchParam }
}
