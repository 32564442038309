const consoleErr = console.error
const SUPPRESSED_WARNINGS = ['Warning:']

console.error = function filterWarnings(msg, ...args) {
  if (msg && msg?.includes && SUPPRESSED_WARNINGS.some((entry) => msg?.includes(entry))) {
    console.warn(msg, ...args)
  } else {
    consoleErr(msg, ...args)
  }
}

export * from '@guiker/address-autocomplete-component'
export * from '@guiker/components-library'
export * from '@guiker/react-helmet'
export * from '@guiker/i18n'
export * from '@guiker/icons'
export * from '@guiker/react-feature-flag'
export * from '@guiker/react-hook-form'
export * from '@guiker/react-query-components'
export * from '@guiker/react-query'
export * from '@guiker/react-utils'
export * from '@guiker/static-asset-context'
export * from '@guiker/config-context'

export * from './use-api-client'
export * from './data-context-provider'
export * from './generate-base-api-context'

export {
  Link as RouterLink,
  Redirect,
  routeConfigBuilder,
  webAppRouterBuilder,
  deprecatedRouterBuilder,
  useLocationQuery,
  useSearchParams,
  useParams,
  Outlet,
  usePaginationQueryParams,
  useNavigate,
  RedirectNoIndex,
  RouteConfig,
} from '@guiker/router'

export {
  Dropdown as CLDropdown,
  Slider as CLSlider,
  DatePicker as CLDatePicker,
  TextField as CLTextField,
  TextFieldProps as CLTextFieldProps,
  CurrencyTextField as CLCurrencyTextField,
  NumberFormat as CLNumberFormat,
  NumericInput as CLNumericInput,
  Checkbox as CLCheckbox,
  RadioGroup as CLRadioGroup,
  DropdownProps as CLDropdownProps,
  Toggle as CLToggle,
  Autocomplete as CLAutocomplete,
  BlockEditor as CLBlockEditor,
} from '@guiker/components-library'

export {
  ApiFormStepContextProvider,
  BlockEditor,
  Dropdown,
  Autocomplete,
  Checkbox,
  Slider,
  Toggle,
  ApiForm,
  ApiFormAction,
  RadioGroup,
  TextField,
  DatePicker,
  CurrencyTextField,
  ApiFormStep,
  useApiFormStepContext,
  NumberFormat,
  HiddenInput,
  ApiFormProps,
  useApiFormContext,
  AsyncAutocomplete,
  NumericInput,
  FragmentComponentProps,
  ApiFormFragments,
  Form,
  TextFieldProps,
  DropdownProps,
  StepWithSchema,
} from '@guiker/rhf-components'
