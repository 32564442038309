import React, { useState } from 'react'

import { clsx, makeStyles, toPx } from '@guiker/components-core'
import { CircularAddIcon } from '@guiker/icons'
import { isArray } from '@guiker/lodash'

import { Flex } from '../../Layout'
import { Menu } from '../../Navigation'
import { Divider } from '../Divider'
import { PSmall } from '../Typography'

export type FilterProps<V> = {
  label: string
  inputLabel?: string
  defaultValue?: V
  valueFormatter: (value: V) => string
  onChange?: (value: V) => unknown
  InputComponent?: React.FC<{ defaultValue: V; value: V; onChange: (value: V) => unknown }>
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderColor: theme.palette.grey[15],
      borderRadius: theme.spacing(1),
      borderStyle: 'solid',
      borderWidth: toPx(1),
      width: 'initial',
      '&:hover': {
        backgroundColor: theme.palette.grey[5],
      },
    },
    label: {
      lineHeight: toPx(16),
    },
    icon: {
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short,
      }),
    },
    iconPlus: {
      transform: 'rotate(0)',
    },
    iconClose: {
      transform: 'rotate(45deg)',
    },
  }),
  { name: 'Filter' },
)

export const Filter = <V,>({
  defaultValue,
  inputLabel,
  label,
  valueFormatter,
  onChange: parentOnChange,
  InputComponent,
}: FilterProps<V>) => {
  const classes = useStyles()
  const [value, setValue] = useState(defaultValue)
  const [anchor, setAnchor] = useState<Element>(null)
  const hasValue = isArray(value) ? value?.length > 0 : !!value

  const onClick = (event?: React.SyntheticEvent) => {
    anchor ? onClose() : setAnchor(event?.currentTarget)
  }

  const onClose = () => {
    setAnchor(null)
  }

  const onChange = (newValue: V) => {
    if (newValue !== value) {
      setValue(newValue)
      parentOnChange?.(newValue)
    }
  }

  const onClear: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation()
    onChange(null)
  }

  return (
    <>
      <Flex gap={1} px={1.5} py={0.5} className={classes.root} alignItems='center' onClick={onClick}>
        <CircularAddIcon
          onClick={onClear}
          size='smaller'
          color={60}
          className={clsx(hasValue ? classes.iconClose : classes.iconPlus)}
        />
        <PSmall color={60} mb={0} className={classes.label}>
          {label}
        </PSmall>
        {hasValue && (
          <>
            <Divider orientation='vertical' />
            <PSmall mb={0} className={classes.label}>
              {valueFormatter ? valueFormatter(value) : (value as string)}
            </PSmall>
          </>
        )}
      </Flex>
      {anchor && InputComponent && (
        <Menu
          maxWidth={200}
          open={!!anchor}
          anchorEl={anchor}
          onClose={onClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 32, horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <Flex flexDirection='column' px={1.5} py={1} gap={1}>
            {inputLabel && (
              <PSmall mb={0} fontWeight={500}>
                {inputLabel}
              </PSmall>
            )}
            <InputComponent value={value} defaultValue={defaultValue} onChange={onChange} />
          </Flex>
        </Menu>
      )}
    </>
  )
}
